var activeStationId = null;
var activeItemId = null;

$(document).ready(function () {
	let isMobile = false;

// device detection
	if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
		|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
		isMobile = true;
	}

	if (isMobile) {
		$(".map-image").draggable({containment: ["parent"], scroll: false});

		// $(".modal-dialog").click(function () {
		// 	$(this).closest('.modal-dialog').toggleClass("open");
		// 	$('.modal-backdrop').toggleClass("background");
		// });
	}

	// init popover
	$(function () {
		$('[data-toggle="popover"]').popover();

		$('area[data-station-id]').parent().off('click').on('click', function () {
			$("#modal-subtitle").html($(this).find('area[data-station-id]').attr('data-station-title'));
			activeStationId = $(this).find('area[data-station-id]').attr('data-station-id');

			$("#popup-content").modal('show');
			updateContent(activeStationId);
		});

		$("#btn-add-item").click(function () {
			activeItemId = null;
			$("#modal-editor").modal('show');
		});
	});

	// Sluit popover als je ergens anders klikt
	$("html").on("mouseup", function (e) {
		let l = $(e.target);
		if (l[0].className.indexOf("popover") === -1) {
			$(".popover").each(function () {
				$(this).popover("hide");
			});
		}
	});

	// Set click poistion for popover

	let clickTop, clickLeft = 0;
	$(document).click(function (e) {
		clickTop = e.pageY;
		clickLeft = e.pageX;

		let popovers = $('[data-toggle="popover"]');
		const popoverClass = $('.popover');
		popovers.popover({
			placement: 'bottom center',
			html: true,
			trigger: 'focus'
		}).on("shown.bs.popover", function (e) {
			$(popoverClass).removeAttr('style');

			$(popoverClass).css({
				top: clickTop,
				left: clickLeft,
			});
		});
	});

	// init summernote
	$('#edit-content').summernote({
		placeholder: '',
		height: 300,
		dialogsInBody: true
	});

	$("#modal-editor").on('shown.bs.modal', function (e) {
		if (activeItemId !== null) {
			Controller.sendRequest('popup', 'getSingleItem', [activeStationId, activeItemId], function (response) {
				if (response.title !== undefined && response.content !== undefined) {
					$("#edit-title").val(decodeURIComponent(response.title));
					$("#edit-content").summernote("code", decodeURIComponent(response.content));
				} else {
					alert('Er is een onbekende fout opgetreden.');
				}
			}, true);
		} else {
			// velden leeg maken
			$("#edit-title").val('');
			$("#edit-content").summernote('code', '');
		}

		$("#modal-editor .btn-success").off('click').on('click', function (e) {
			if ($("#edit-title").val() === '' || $("#edit-content").summernote('code') === '') {
				Message.addError('Niet alle velden zijn ingevuld.');
				Message.showMessages();
			} else {
				Controller.sendRequest('popup', 'saveItem', [activeStationId, activeItemId, {
					'title': encodeURIComponent($("#edit-title").val()),
					'content': encodeURIComponent($("#edit-content").summernote('code'))
				}], function (response) {
					if (response.error !== undefined && response.error !== false) {
						alert('Er is een fout opgetreden tijdens het opslaan.');
					} else {
						updateContent(activeStationId);
						$("#modal-editor").modal('toggle');
					}
				});
			}
		});
	});


	// $('.slick-modal-container').slick({
	// 	infinite: true,
	// 	speed: 300,
	// 	slidesToShow: 1,
	// 	centerMode: true,
	// 	centerPadding: '10%',
	// 	slidesToScroll: 1,
	// 	dots: false,
	// 	prevArrow: false,
	// 	nextArrow: false,
	// 	responsive: [
	// 		{
	// 			breakpoint: 1280,
	// 			settings: "unslick"
	// 		},
	// 		{
	// 			breakpoint: 800,
	// 			settings: "slick"
	// 		}
	// 		// You can unslick at a given breakpoint now by adding:
	// 		// settings: "unslick"
	// 		// instead of a settings object
	// 	]
	// });
});

function updateContent(stationId) {
	jQuery.xhrPool.abortAll();

	let prevStation = $('area[data-station-id=\'' + stationId + '\']').attr('data-station-previous');
	let nextStation = $('area[data-station-id=\'' + stationId + '\']').attr('data-station-next');

	// previous button
	$('#prev-item').off('click');
	if (prevStation === undefined || prevStation === '' || prevStation === null) {
		$('#prev-item').hide();
	} else {
		$('#prev-item').show();
		$('#prev-item').on('click', function (e) {
			updateContent(prevStation);
		});
	}

	// next button
	$('#next-item').off('click');
	if (nextStation === undefined || nextStation === '' || nextStation === null) {
		$('#next-item').hide();
	} else {
		$('#next-item').show();
		$('#next-item').on('click', function (e) {
			updateContent(nextStation);
		});
	}


	// Mobile previous button
	$('#prev-item-mobile').off('click');
	if (prevStation === undefined || prevStation === '' || prevStation === null) {
		$('#prev-item-mobile').hide();
	} else {
		$('#prev-item-mobile').show();
		$('#prev-item-mobile').on('click', function (e) {
			updateContent(prevStation);
		});
	}

	// Mobile next button
	$('#next-item-mobile').off('click');
	if (nextStation === undefined || nextStation === '' || nextStation === null) {
		$('#next-item-mobile').hide();
	} else {
		$('#next-item-mobile').show();
		$('#next-item-mobile').on('click', function (e) {
			updateContent(nextStation);
		});
	}

	activeStationId = stationId;
	activeItemId = null;

	Controller.sendRequest('popup', 'getFirstItem', [stationId], function (response) {
		if (response.title !== undefined && response.content !== undefined && response.title !== null && response.content !== null && response.title.length > 0 && response.content.length > 0) {
			let html = $(Mustache.render(document.getElementById("itemTemplate").innerHTML, {
				id: response.id,
				title: decodeURIComponent(response.title),
				content: decodeURIComponent(response.content)
			}));

			html.find('.action-edit[data-item-id]').off('click').on('click', function (e) {
				activeItemId = $(this).attr('data-item-id');
				$("#modal-editor").modal('show');
			});

			html.find('.action-remove[data-item-id]').off('click').on('click', function (e) {
				if(window.confirm('Weet u zeker dat u dit item wilt verwijderen?')) {
					activeItemId = $(this).attr('data-item-id');
					Controller.sendRequest('popup', 'removeItem', [activeItemId], function (response) {
						updateContent(activeStationId);
					});
				}
			});

			$("#popup-content .modal-body .first-item").html(html);
		} else {
			$("#popup-content .modal-body .first-item").html('');
		}
	}, true);
	$('.no-items-found').hide();
	$("#input-search").off('keyup').on('keyup', function (e) {
		if (e.which === 13) {
			let searchTerm = $("#input-search").val().toLowerCase();
			$('.archive-items .searchHit').removeClass('searchHit');

			if (searchTerm.length > 0) {
				// start search
				$('.archive-items .searchable').filter(function () {
					return $(this).text().toLowerCase().indexOf(searchTerm) > -1 ? true : false;
				}).each(function () {
					if (!$(this).hasClass('searchHit')) {
						$(this).addClass('searchHit');
					}
				});

				$('.archive-items .searchable:not(.searchHit)').hide();
				$('.archive-items .searchHit').show();
			} else {
				$('.archive-items .searchable').show();
			}

			if ($('.archive-items .searchHit:visible').length < 1) {
				$('.no-items-found').show();
			} else {
				$('.no-items-found').hide();
			}
		}
	});

	Controller.sendRequest('popup', 'getArchive', [stationId], function (response) {
		if (response.length !== undefined && response.length > 0) {
			$("#popup-content .modal-body .archive-items").html('');
			response.forEach(function (item) {
				let itemHtml = $(Mustache.render(document.getElementById("itemTemplate").innerHTML, {
					id: item.id,
					title: decodeURIComponent(item.title),
					content: decodeURIComponent(item.content)
				}));

				itemHtml.find('.action-edit[data-item-id]').off('click').on('click', function (e) {
					activeItemId = $(this).attr('data-item-id');
					$("#modal-editor").modal('show');
				});

				itemHtml.find('.action-remove[data-item-id]').off('click').on('click', function (e) {
					if(window.confirm('Weet u zeker dat u dit item wilt verwijderen?')) {
						activeItemId = $(this).attr('data-item-id');
						Controller.sendRequest('popup', 'removeItem', [activeItemId], function (response) {
							updateContent(activeStationId);
						});
					}
				});

				$("#popup-content .modal-body .archive-items").append(itemHtml);
				$("#search-line").show();
			});
		} else {
			$("#search-line").hide();
			$("#popup-content .modal-body .archive-items").html('');
		}
	}, true);

	$("#modal-subtitle").html($('area[data-station-id=\'' + stationId + '\']').attr('data-station-title'));

	let modalBody = $(".modal-body");
	let modalDialog = $(".modal-dialog");
	let modalBackground = $(".modal-backdrop");

	// Variabel voor he;t checken of de modal volledig geopend is
	let modalisOpened = false;
	modalDialog.touchwipe({
		wipeLeft: function () {
			updateContent(prevStation);
			console.log('Left');
			//alert("left");
		},
		wipeRight: function () {
			updateContent(nextStation);
			//alert("right");
		},

		wipeUp: function () {

			if(modalisOpened) {
				if($("#popup-content .modal-content").scrollTop() <= 1) {
					modalDialog.removeClass("open");
					modalBackground.removeClass("background");
					modalisOpened = false;
				}
			}
			// modalBackground.hide();

			console.log("down");

		},
		wipeDown: function () {
			if (modalisOpened) {
				modalisOpened = false;
				console.log('Changed swiper scroll settings');
			} else {
				modalDialog.addClass("open");
				modalBackground.addClass("background");
				modalisOpened = true;
			}

			console.log("up");

		},
		min_move_x: 20,
		min_move_y: 20,
		preventDefaultEvents: false,
	});

	// let modalBody = $(".modal-body");
	// let modalDialog = $(".modal-dialog");
	// let modalBackground = $(".modal-backdrop");
	//
	// // Variabel voor he;t checken of de modal volledig geopend is
	// let modalisOpened = false;
	// modalDialog.swipe({
	//
	// 	swipeUp: function () {
	// 		modalDialog.addClass("open");
	// 		modalBackground.addClass("background");
	//
	// 		modalisOpened = true;
	//
	// 		if (modalisOpened) {
	// 			modalDialog.swipe('option', 'allowPageScroll', 'vertical');
	//
	// 			console.log('Changed swiper scroll settings');
	// 		}
	// 		console.log("up");
	// 	},
	//
	// 	swipeDown: function () {
	// 		//$('body').removeClass('modal-open');
	//
	// 		// modal.removeClass("show");
	// 		// modal.css("display", "none");
	// 		// modal.attr("aria-hidden", "true");
	// 		// modal.removeAttr("aria-modal", "true");
	// 		//
	// 		modalDialog.removeClass("open");
	// 		//
	// 		modalBackground.removeClass("background");
	// 		// modalBackground.hide();
	//
	//
	// 		console.log("down");
	// 	},
	//
	// 	swipeLeft: function () {
	// 		updateContent(prevStation);
	// 		console.log('Swipe links');
	// 	},
	// 	swipeRight: function () {
	// 		updateContent(nextStation);
	// 		console.log('Swipe rechts');
	// 	},
	//
	// 	triggerOnTouchEnd: true,
	// 	threshold: 200
	// });

}

/**
 * Ajax Request Pool
 *
 * @author Oliver Nassar <onassar@gmail.com>
 * @see    http://stackoverflow.com/questions/1802936/stop-all-active-ajax-requests-in-jquery
 */
jQuery.xhrPool = [];

/**
 * jQuery.xhrPool.abortAll
 *
 * Retrieves all the outbound requests from the array (since the array is going
 * to be modified as requests are aborted), and then loops over each of them to
 * perform the abortion. Doing so will trigger the ajaxComplete event against
 * the document, which will remove the request from the pool-array.
 *
 * @access public
 * @return void
 */
jQuery.xhrPool.abortAll = function() {
	var requests = [];
	for (var index in this) {
		if (isFinite(index) === true) {
			requests.push(this[index]);
		}
	}
	for (index in requests) {
		requests[index].abort();
	}
};

/**
 * jQuery.xhrPool.remove
 *
 * Loops over the requests, removes it once (and if) found, and then breaks out
 * of the loop (since nothing else to do).
 *
 * @access public
 * @param  Object jqXHR
 * @return void
 */
jQuery.xhrPool.remove = function(jqXHR) {
	for (var index in this) {
		if (this[index] === jqXHR) {
			jQuery.xhrPool.splice(index, 1);
			break;
		}
	}
};

/**
 * Below events are attached to the document rather than defined the ajaxSetup
 * to prevent possibly being overridden elsewhere (presumably by accident).
 */
$(document).ajaxSend(function(event, jqXHR, options) {
	jQuery.xhrPool.push(jqXHR);
});
$(document).ajaxComplete(function(event, jqXHR, options) {
	jQuery.xhrPool.remove(jqXHR);
});


$.fn.maphilight.defaults = {
	fill: true,
	fillColor: '000000',
	fillOpacity: 0.2,
	stroke: true,
	strokeColor: 'ff0000',
	strokeOpacity: 0,
	strokeWidth: 0,
	fade: true,
	alwaysOn: false,
	neverOn: false,
	groupBy: false,
	wrapClass: true,
	shadow: false,
	shadowX: 0,
	shadowY: 0,
	shadowRadius: 6,
	shadowColor: '000000',
	shadowOpacity: 0.8,
	shadowPosition: 'outside',
	shadowFrom: false
};


// Add station / item styling (area marker)
//$('.map-image').maphilight();